import LoginForm from "../componentes/LoginForm"
import logo from '../assets/logo.png';
import {Image} from "@nextui-org/react";
const Login = () => {
    return (
        <main className="flex flex-col items-center justify-center w-full h-screen ">
            <Image src={logo}  style={{height:70}}  className="mb-3"/>
            <div className="">
                <LoginForm />
            </div>

        </main>

    )
}
export default Login