import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell } from "@nextui-org/react";
import { User } from "@nextui-org/react";
import { Chip } from "@nextui-org/react";
import { Tooltip } from "@nextui-org/react";
import { MdModeEditOutline } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { useEffect, useState } from "react";
import { useSocket } from "../../contexts/SocketContext";
import { Button } from "@nextui-org/react";
import { TiUserAdd } from "react-icons/ti";
import AddUser from "./Adduser";
import  toast  from "react-hot-toast";
import { useAuth } from '../../contexts/AuthContext';
import { FaUserLock } from "react-icons/fa6";
import { FaUserCheck } from "react-icons/fa";
import EditUser from "./EditUser";

const TablaUsuarios = () => {

    const [users, setUsers] = useState([]);
    const socket = useSocket();
    const [addNew, setAddNew] = useState(false);
    const [edit, setEdit] = useState(false);
    const { logout } = useAuth();

    useEffect(() => {
        // Eliminar el oyente del evento antes de añadir uno nuevo
        socket.off('usersList');
        socket.off('addUserResponse');
        socket.off('deleteUserResponse');
        socket.off('blockOrUnblockUserResponse');
        socket.off('editUserResponse');

        
        // Llamar a getUsers
        socket.emit('getUsers', localStorage.getItem('token-autocall-ig'));


        // Escuchar el evento de respuesta 'getUsers' del servidor
        socket.on('usersList', (response) => {
            setUsers(response);
        });
        
        //Escuchar el evento de respuesta 'addUserResponse' del servidor
        socket.on('addUserResponse', (response) => {
            if(response.code === 401){
                toast.error(response.message);
                logout();
                
            }
            if(response.code !== 200 && response.code !== 401){
                toast.error(response.message);
            }
                      

            if(response.code === 200){
                toast.success('Usuario añadido correctamente');
                setAddNew(false);
                socket.emit('getUsers');
            }
        });

        //Escuchar el evento de respuesta 'deleteUserResponse' del servidor
        socket.on('deleteUserResponse', (response) => {
            if(response.code === 401){
                toast.error(response.message);
                logout();
            }
            if(response.code !== 200 && response.code !== 401){
                toast.error(response.message);
            }
            if(response.code === 200){
                toast.success('Usuario eliminado correctamente');
                socket.emit('getUsers');
            }
        });

        //Escuchar el evento de respuesta 'blockOrUnblockUserResponse' del servidor
        socket.on('blockOrUnblockUserResponse', (response) => {
            if(response.code === 401){
                toast.error(response.message);
                logout();
            }
            if(response.code !== 200 && response.code !== 401){
                toast.error(response.message);
            }
            if(response.code === 200){
                toast.success('Usuario modificado correctamente');
                socket.emit('getUsers');
            }
        });

        //Escuchar el evento de respuesta 'editUserResponse' del servidor
        socket.on('editUserResponse', (response) => {
            if(response.code === 401){
                toast.error(response.message);
                logout();
            }
            if(response.code !== 200 && response.code !== 401){
                toast.error(response.message);
            }
            if(response.code === 200){
                toast.success('Usuario modificado correctamente');
                socket.emit('getUsers');
            }
        });





        // Devolver una función de limpieza que se ejecutará cuando el componente se desmonte
        return () => {
            socket.off('usersList');
            socket.off('addUserResponse');
            socket.off('deleteUserResponse');
            socket.off('blockOrUnblockUserResponse');
            socket.off('editUserResponse');
        };
        // eslint-disable-next-line
    }, [socket]);

    const handleClose = () => {
        setAddNew(false);
    }
    const handleCloseEdit = () => {
        setEdit(false);
    }

    const eliminarUsuario = (id) => {
        window.confirm('¿Estás seguro de eliminar este usuario?') &&    
  
        socket.emit('deleteUser', id, localStorage.getItem('token-autocall-ig'));
    }

    const blockOrUnblockUser = (id, activo) => {
    
        const message = activo ? '¿Estás seguro de desactivar este usuario?' : '¿Estás seguro de activar este usuario?';
        window.confirm(message) &&    
        socket.emit('blockOrUnblockUser', id, localStorage.getItem('token-autocall-ig'));
    }



    return (
        <>
            <Button color="primary"  startContent={<TiUserAdd />} className="mb-3" onClick={() => setAddNew(true)}>
                Agregar usuario
            </Button>
            <Table>
                <TableHeader>
                    <TableColumn>Usuario</TableColumn>
                    <TableColumn>Empresa</TableColumn>
                    <TableColumn>Estado</TableColumn>
                    <TableColumn>Acciones</TableColumn>
                </TableHeader>
                <TableBody>
                    {
                        users.map((user, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <User
                                        name={user.username}
                                        description={user.nombre + " " + user.apellido}
                                        avatarProps={{
                                            src: user.avatar ?? "",
                                        }}
                                    />
                                </TableCell>
                                <TableCell>{user.empresa}</TableCell>
                                <TableCell>
                                <Tooltip content={user.activo ? "El usuario está activo" : "El usuario está inactivo, no podrá iniciar sesión"}>
                                    <Chip color={user.activo ? "success" : "danger"}>
                                        {user.activo ? "Activo" : "Inactivo"}
                                    </Chip>
                                </Tooltip>
                                </TableCell>
                                <TableCell>
                                    <div className="relative flex items-center gap-2">
                                     
                                        <Tooltip content="Editar usuario">
                                            <span className="text-lg text-default-400 cursor-pointer active:opacity-50" onClick={() => setEdit(user)}>
                                                <MdModeEditOutline />
                                            </span>
                                        </Tooltip>
                                        <Tooltip content={user.activo ? "Desactivar usuario" : "Activar usuario"}>
                                            <span className="text-lg text-default-400 cursor-pointer active:opacity-50" onClick={() => blockOrUnblockUser(user.id, user.activo)}>
                                                {user.activo ? <FaUserLock /> : <FaUserCheck />}
                                            </span>
                                        </Tooltip>
                                        <Tooltip color="danger" content="Eliminar usuario" >
                                            <span className="text-lg text-danger cursor-pointer active:opacity-50" onClick={() => eliminarUsuario(user.id)}>
                                                <MdDelete />
                                            </span>
                                        </Tooltip>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))
                    }

                </TableBody>
            </Table>
            <EditUser open={edit} close={handleCloseEdit} />
            <AddUser open={addNew} close={handleClose} />
        </>
    );
}
export default TablaUsuarios;