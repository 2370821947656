import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell } from "@nextui-org/react";
import { useSocket } from '../../contexts/SocketContext';
import { useEffect, useState, useMemo } from 'react';
import { Pagination, Button } from "@nextui-org/react";
import {sqlDateToJsDate} from '../../tools/tools';
const ListadoLogs = () => {
    const socket = useSocket();
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        socket.off('getLogs');

        socket.emit('getLogs', localStorage.getItem('token-autocall-ig'));

        socket.on('getLogsResponse', (response) => {
            setLogs(response);
            console.log(response);
        });


        return () => {
            socket.off('getLogs');
            socket.off('getLogsResponse');

        }
    }, [socket]);

    const [page, setPage] = useState(1);
    const rowsPerPage = 15;

    const pages = Math.ceil(logs.length / rowsPerPage);

    const items = useMemo(() => {
        const start = (page - 1) * rowsPerPage;
        const end = start + rowsPerPage;

        return logs.slice(start, end);
    }, [page, logs]);


    return (
        <div>   
            <Button
                auto
                className="mb-3"
                size="small"
                color="warning"
                onClick={() => socket.emit('clearLogs', localStorage.getItem('token-autocall-ig'))}
            >
                Limpiar logs
            </Button>

            <Table
                aria-label="Logs list"
                bottomContent={
                    <div className="flex w-full justify-center">
                        <Pagination
                            isCompact
                            showControls
                            showShadow
                            color="primary"
                            page={page}
                            total={pages}
                            onChange={(page) => setPage(page)}
                        />
                    </div>
                }
                classNames={{
                    wrapper: "min-h-[222px]",
                }}
            >
                <TableHeader>
                    <TableColumn>ID</TableColumn>
                    <TableColumn>Mensaje</TableColumn>
                    <TableColumn>Fecha</TableColumn>
                </TableHeader>
                <TableBody items={items}>
                    {(item) => (
                        <TableRow key={item.id}>
                            <TableCell>{item.id}</TableCell>
                            <TableCell>{item.message}</TableCell>
                            <TableCell>{sqlDateToJsDate(item.fecha).toLocaleString()}</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <p className="text-gray-400 text-[12px] mt-2">Las horas de la tabla son dos horas menos que la hora corecta</p>
        </div>
    );
}
export default ListadoLogs;