import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import { Image } from "@nextui-org/react";
import { IoIosPersonAdd } from "react-icons/io";
import { TiDownload } from "react-icons/ti";
import { FaChartSimple } from "react-icons/fa6";
import { FaUsers } from "react-icons/fa";
import { BsNutFill } from "react-icons/bs";
import { Tooltip } from "@nextui-org/react";
import { MdSpaceDashboard } from "react-icons/md";
import { useAuth } from '../contexts/AuthContext';
import toast from 'react-hot-toast';
import { TbLayoutSidebarLeftCollapseFilled } from "react-icons/tb";
import { TbLayoutSidebarRightCollapseFilled } from "react-icons/tb";
import { useState } from 'react';
import { MdLocalPhone } from "react-icons/md";
import { IoLogOut } from "react-icons/io5";
import { FaListUl } from "react-icons/fa";
import { FaBug } from "react-icons/fa";
const MenuLateral = () => {

  const { logout } = useAuth();
  const [collapsed, setCollapsed] = useState(false);

  return (
    <div className="grid grid-cols-1">
      <Sidebar className='min-h-full' collapsed={collapsed} collapsedWidth="60px" >
        <div className="w-full flex justify-center align-center p-8">
          <Link to='/dashboard'>
            <Image src={logo} style={{ height: 60, objectFit: 'contain' }} alt="logo" />
          </Link>
        </div>
        <div >

          <Menu>
            {
              collapsed ? null : <p className='text-xs font-medium ml-4 mb-1 text-gray-400'>Principal</p>
            }
            <Link to='/'>
              <MenuItem active icon={<MdSpaceDashboard style={{ color: '#4f84a1', fontSize: '17px' }} />} className='text-[13px] font-normal'>
                Dashboard
              </MenuItem>
            </Link>
            <Link to='/anadircontacto'>
              <MenuItem active icon={<IoIosPersonAdd style={{ color: '#4f84a1', fontSize: '17px' }} />} className='text-[13px] font-normal'>
                Añadir Contactos
              </MenuItem>
            </Link>
            <Link to='/listadocontactos'>
              <MenuItem icon={<FaListUl style={{ color: '#4f84a1', fontSize: '17px' }} />} className='text-[13px] font-normal'>
                Listado de contactos
              </MenuItem>
            </Link>
            <Link to='/descargarcontacto'>
              <MenuItem icon={<TiDownload style={{ color: '#4f84a1', fontSize: '17px' }} />} className='text-[13px] font-normal'>
                Descargar Contactos
              </MenuItem>
            </Link>
            <Link to='/confllamadas'>
              <MenuItem icon={<MdLocalPhone style={{ color: '#4f84a1', fontSize: '17px' }} />} className='text-[13px] font-normal'>
                Configuración llamadas
              </MenuItem>
            </Link>
         
          </Menu>
        </div>
        <div className='mt-8'>
          <Menu>
            {
              collapsed ? null : <p className='text-xs font-medium ml-4 mb-1 text-gray-400'>Soporte</p>
            }
            <Link to='/estadisticas'>
            <MenuItem icon={<FaChartSimple style={{ color: '#4f84a1', fontSize: '17px' }} />} className='text-[13px] font-normal'>
              Estadísiticas
            </MenuItem>
            </Link>
            <Link to='/logs'>
            <MenuItem icon={<FaBug style={{ color: '#4f84a1', fontSize: '17px' }} />} className='text-[13px] font-normal'>
              Logs
            </MenuItem>
            </Link>
            <Link to='/usuarios'>
            <MenuItem icon={<FaUsers style={{ color: '#4f84a1', fontSize: '17px' }} />} className='text-[13px] font-normal'>
              Usuarios
            </MenuItem>
            </Link>
          </Menu>
        </div>
        <div className='mt-8'>
          <Menu>
            {
              collapsed ? null : <p className='text-xs font-medium ml-4 mb-1 text-gray-400'>Sessión</p>
            }
                 <Link to='/configuracion'>
              <MenuItem icon={<BsNutFill style={{ color: '#4f84a1', fontSize: '17px' }} />} className='text-[13px] font-normal'>
                Configuración
              </MenuItem>
            </Link>
            <MenuItem icon={<IoLogOut style={{ color: '#4f84a1', fontSize: '17px' }} />} className='text-[13px] font-normal' onClick={() => { logout(); toast.success('Sessión cerrada') }}>
              Cerrar Sessión
            </MenuItem>
            
          </Menu>
        </div>
        <div className='absolute bottom-0 right-0 cursor-pointer flex ' onClick={() => { setCollapsed(!collapsed) }}>
          <Tooltip showArrow={true} size='sm' position='top'  content={collapsed ? 'Expandir' : 'Colapsar'} >
            <p className=''>
              {
                collapsed ? <TbLayoutSidebarRightCollapseFilled className='text-[#4f84a1] text-2xl mr-2 mb-2' /> : <TbLayoutSidebarLeftCollapseFilled className='text-[#4f84a1] text-2xl mr-2 mb-2' />
              }
            </p>

          </Tooltip>

        </div>

      </Sidebar>
    </div>

  );
}
export default MenuLateral;