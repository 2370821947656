import { Select, SelectItem, Slider } from "@nextui-org/react";
import { useState } from "react";
import { useSocket } from "../../contexts/SocketContext";
import { useEffect } from "react";
const FormularioDescargarContactos = () => {
    const [callNew, setCallNew] = useState(0);
    const [empresa, setEmpresa] = useState('');
    const [horario, setHorario] = useState('');
    const [totalContactos, setTotalContactos] = useState(0);
    const socket = useSocket();



    useEffect(() => {
        socket.off('contactsList');
        socket.off('downloadContacts');
        socket.on('contactsList', (data) => {
           if(data.code === 200){
                setTotalContactos(data.contacts);
           }
        });

        socket.on('downloadContacts', (excell) => {
            const url = window.URL.createObjectURL(new Blob([excell]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'contactos.xlsx');
            document.body.appendChild(link);
            link.click();
        }
        );




        return () => {
            socket.off('contactsList');
            socket.off('downloadContacts');
        }
    }, []);

    const handleDescargarContactos = () => {
        socket.emit('descargarContactos', localStorage.getItem('token-autocall-ig'), empresa, horario, callNew);
    }



   const handleFiltroContactos = (tipo, valor) => {
    let tempEmpresa = empresa;
    let tempHorario = horario;
    let tempCallNew = callNew;

    if(tipo === 'empresa'){
        tempEmpresa = valor;
        setEmpresa(valor);
    }
    if(tipo === 'horario'){
        tempHorario = valor;
        setHorario(valor);
    }
    if(tipo === 'callNew'){
        tempCallNew = valor;
        setCallNew(valor);
    }

    socket.emit('filtroContactos', localStorage.getItem('token-autocall-ig'), tempEmpresa, tempHorario, tempCallNew);
}

    return (
        <div className="p-4 mt-4 rounded-md bg-gray-100">
            <p className="text-gray-400 text-[13px]">Selecciona la empresa donde deseas descargar los contactos</p>
            <Select placeholder="Seleccionar empresa" className="w-full mb-4 " onChange={(e) => handleFiltroContactos('empresa', e.target.value)}>
                <SelectItem value="Integram" key="Integram">Integram</SelectItem>
                <SelectItem value="JQS" key="JQS">JQS</SelectItem>
                <SelectItem value="Kram" key="Kram">Kram</SelectItem>
            </Select>
            <p className="text-gray-400 text-[13px]">Seleccionar horarios para descargar los contactos</p>
            <Select placeholder="Seleccionar horario" className="w-full mb-4" onChange={(e) => handleFiltroContactos('horario', e.target.value)}>
                <SelectItem value="Mañana" key="Mañana">Mañana</SelectItem>
                <SelectItem value="Tarde" key="Tarde">Tarde</SelectItem>
                <SelectItem value="Todo" key="Todo" >Todo el día</SelectItem>
            </Select>


            {totalContactos > 0 && (
            <Slider className="mt-3"
                label="Cantidad de contactos a descargar"
                value={callNew}
                onChange={(val) => handleFiltroContactos('callNew', val)}
                step={1}
                maxValue={totalContactos}
                minValue={0}
            />
            )}
            <div className="mt-3 text-xs p-2">
                {
                    callNew > 0 && empresa && horario && (
                        <>
                            
                            <button className="bg-primary mt-4 mb-2 text-white px-4 py-2 rounded-md"
                                onClick={handleDescargarContactos}
                            >Descargar</button>
                            <p>
                                Has seleccionado: <span className="text-primary">{callNew} contactos</span> de la empresa <span className="text-primary">{empresa}</span> en el horario <span className="text-primary">{horario}</span></p>
                        </>

                    )
                }



            </div>
        </div>
    );
}
export default FormularioDescargarContactos;