import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token-autocall-ig') || null);
  
  const [isLogged, setIsLogged] = useState(!!token);

  useEffect(() => {
    setIsLogged(!!token);
  }, [token]); 

  const login = (newToken) => {
    localStorage.setItem('token-autocall-ig', newToken);
    setToken(newToken);
  };

  const userInfo = (user) => {
    localStorage.setItem('user-autocall-ig', JSON.stringify(user));
  };

  const logout = () => {
    localStorage.removeItem('token-autocall-ig');
    localStorage.removeItem('user-autocall-ig');
    setToken(null);
  };

  return (
    <AuthContext.Provider value={{ token, login, logout, isLogged,userInfo }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
