import { Button, Card, CardBody, CardHeader } from "@nextui-org/react";
import { Input, Code } from "@nextui-org/react";
import { IoCall } from "react-icons/io5";
import { useState, useEffect } from "react";
import { useSocket } from "../../contexts/SocketContext";
import toast from "react-hot-toast";

const Test = () => {

    const socket = useSocket();
    const [numero, setNumero] = useState('');
    const [response, setResponse] = useState('');

    const handleLlamada = () => {
        if (numero === '') {
            toast.error('Introduce un número de teléfono');
            return;
        }
        socket.emit('testCall', localStorage.getItem('token-autocall-ig'), numero);
    }

    useEffect(() => {
        socket.off('callResponse');

        socket.on('callResponse', (response) => {
            setResponse(response);
        });

        return () => {
            socket.off('callResponse');
        };
        // eslint-disable-next-line
    }, [numero]);

    return (
        <div className="mt-5">
            <Card className="p-3 w-full" shadow >
                <CardHeader className="w-full">
                    <div className="flex flex-col w-full">
                        <p className="text-gray-400 text-[13px]">da de prueva</p>
                        <div className="flex items-center justify-center mt-4 gap-1">
                            <Input
                                type="number"
                                placeholder="666 666 666"
                                labelPlacement="outside"
                                value={numero}
                                onChange={(e) => setNumero(e.target.value)}
                                startContent={
                                    <IoCall className="text-default-400 pointer-events-none flex-shrink-0" />
                                }
                            />
                            <Button color="primary" auto onClick={handleLlamada}>
                                <IoCall className="color-white" />
                            </Button>

                        </div>

                    </div>

                </CardHeader>

                <CardBody>
                {response && <>
                    <Code>
                     
                    Respuesta de la llamada: <span className={ response.status === 'Success' ? 'text-success' : 'text-danger' }>{response.status}</span> uniqueId: {response.uniqueId}

                        <br /> 
                        <br /> 
                        <span className="text-gray-400"> El status solo corresponde a si la llamada ha sido respondida (rechazandola o aceptandola)</span>
                        
                        </Code>
                        </>}


                </CardBody>

            </Card>

        </div>
    );
}
export default Test;