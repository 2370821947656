import { Card, CardBody } from '@nextui-org/react';
import { Progress } from "@nextui-org/react";
import { useSocket } from '../../contexts/SocketContext';
import { useEffect } from "react";
import { useState } from "react";
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'react-hot-toast';
const Estadísticas = () => {
    const socket = useSocket();
    const { logout } = useAuth();
    const [totalLlamadas, setTotalLlamadas] = useState(0);
    const [llamadasContestadas, setLlamadasContestadas] = useState(0);
    const [llamadasNoContestadas, setLlamadasNoContestadas] = useState(0);


    useEffect(() => {
        socket.off('EstadisticasRapidas');

        socket.emit('getEstadisticasRapidas', localStorage.getItem('token-autocall-ig'));

        socket.on('EstadisticasRapidas', (data) => {
            if (data.code === 401) {
                toast.error(data.message);
                logout();

            }
            setTotalLlamadas(data.totalLlamadas);
            setLlamadasContestadas(data.llamadasContestadas);
            setLlamadasNoContestadas(data.llamadasNoContestadas);

        }
        );


    
        return () => {
            socket.off('EstadisticasRapidas');
        };
    }, []);

    return (
        <>
            <Card className="p-3 h-full">
                <CardBody>
                    <h2 className="text-xl">
                        Estadísticas Rápidas
                    </h2>
                    <p className="text-gray-400 text-[13px]">Información llamadas en tiempo real</p>
                    <div className="mt-8 flex flex-col gap-4">


                        <Progress
                            className='mt-4'
                            size="sm"
                            radius="sm"
                            color='primary'
                            label="Contactos que han contestado"
                            value={llamadasContestadas}
                            showValueLabel={true}
                        />
                        <Progress
                            className='mt-4'
                            size="sm"
                            radius="sm"
                            color='primary'
                            label="Contactos que no han contestado"
                            value={llamadasNoContestadas}
                            showValueLabel={true}
                        />
                        <p className="text-medium text-center mt-10">Total de llamdas realizadas <span className="text-primary-600">{totalLlamadas}</span></p>
                    </div>
                </CardBody>
            </Card>

        </>
    )
}
export default Estadísticas;