import { Breadcrumbs, BreadcrumbItem } from "@nextui-org/react";
import ListadoLogs from "../componentes/listadologs/ListadoLogs";

const Logs = () => {
    return (
        <section>
        <Breadcrumbs isDisabled className="mb-6">
            <BreadcrumbItem>Soporte</BreadcrumbItem>
            <BreadcrumbItem active>Logs</BreadcrumbItem>
        </Breadcrumbs>
        <h2 className="text-xl">
            Listado de logs
        </h2>
        <p className="text-gray-400 text-[13px]">Revisa los logs del servicio</p>
        <div className="py-3">
            <ListadoLogs />
        </div>
        
    </section>
    );
    }
export default Logs;