import { Breadcrumbs, BreadcrumbItem } from "@nextui-org/react";
import FileUploadComponent from "../componentes/anadircontactos/uploadFile";

const AnadirContacto = () => {
    return (
        <section>
            <Breadcrumbs isDisabled className="mb-6">
                <BreadcrumbItem>Principal</BreadcrumbItem>
                <BreadcrumbItem active>Añadir contactos</BreadcrumbItem>
            </Breadcrumbs>
            <h2 className="text-xl">
                Añadir contactos
            </h2>
            <p className="text-gray-400 text-[13px]">Sube un excel con contactos directamente de SABI </p>
            <div className="py-3">
            <FileUploadComponent />
            </div>
        
        </section>
    );
}
export default AnadirContacto;