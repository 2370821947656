import { Button, Card, CardBody, CardHeader, Image, Tooltip, Skeleton } from "@nextui-org/react";
import { FaInfoCircle } from "react-icons/fa";
import integram from '../../assets/empresas/integram.png';
import jqs from '../../assets/empresas/jqs.png';
import kram from '../../assets/empresas/kram.png';
import { FiSend } from "react-icons/fi";
import { Progress } from "@nextui-org/react";
import { useSocket } from '../../contexts/SocketContext';
import { useEffect, useState } from 'react';
import ModalEnviarEmpresas from './ModalEnviarEmpresas';


const Empresas = () => {
    const socket = useSocket();
    const [empresas, setEmpresas] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [empresa, setEmpresa] = useState(null);

    useEffect(() => {
        socket.off('getValoresEmpresas');

        socket.emit('getValoresEmpresas', localStorage.getItem('token-autocall-ig'));

        socket.on('valoresEmpresasResponse', (response) => {
            setEmpresas(response);
            console.log(response);
        });

        return () => {
            socket.off('getValoresEmpresas');
        }
    }, [socket]);


    const handleEnviarContactos = (empresa) => {
        setOpenModal(true);
        setEmpresa(empresa);
   
    }



    return (
        empresas.length > 0 ? (
            <div className="grid grid-cols-3 gap-3 min-h-full">
                {empresas.map((empresa) => (
                <Card key={empresa.id} className="p-3 flex flex-col justify-between">
                    <div>
                        <CardHeader className="flex gap-3">
                            <Image
                                alt={empresa.nombre}
                                height={40}
                                radius="sm"
                                src={empresa.nombre === 'Integram Consilis' ? integram : empresa.nombre === 'JQS' ? jqs : kram}
                                width={30}
                            />
                            <div className="flex flex-col">
                                <p className="text-md">{empresa.nombre}</p>
                                <p className="text-small text-default-500">{empresa.descripcion}</p>
                            </div>
                        </CardHeader>
                        <CardBody className=" flex flex-col justify-center ">
                            <h2 className="text-4xl flex  gap-1">
                                {empresa.contactosListosParaDescargar} <span className="text-gray-400 text-[13px]">/ {empresa.totalContactos}</span>
                            </h2>
                            <p className="text-gray-400 text-[13px]">Contactos listos para enviar</p>
                            <div className="pt-4 pb-4">
                                <Progress
                                    className='mt-2'
                                    size="sm"
                                    radius="sm"
                                    label={"Total llamadas hoy: " + empresa.totalLlamadas}
                                    value={empresa.totalLlamadas}


                                />

                                <Progress
                                    className='mt-2'
                                    size="sm"
                                    radius="sm"
                                    label={"No contestan: " + empresa.totalLlamadasNoContestadas}
                                    value={isNaN(empresa.totalLlamadasNoContestadas / empresa.totalLlamadas * 100) ? 0 : empresa.totalLlamadasNoContestadas / empresa.totalLlamadas * 100}
                                    showValueLabel={true}
                                />

                                <Progress
                                    className='mt-2'
                                    size="sm"
                                    radius="sm"
                                    label={"Contestan: " + empresa.totalLlamadasContestadas}
                                    value={isNaN(empresa.totalLlamadasContestadas / empresa.totalLlamadas * 100) ? 0 : empresa.totalLlamadasContestadas / empresa.totalLlamadas * 100}
                                    showValueLabel={true}
                                />

                            </div>
                        </CardBody>
                    </div>
                    <div >
                        <p className="text-gray-400 text-[12px]">Acciones</p>
                        <div className="flex items-center mt-1 gap-1">
                            <div className="bg-primary rounded-full text-white">
                                <Tooltip showArrow={true} content="Enviar contactos" size='sm' position='top' >
                                    <Button className="bg-primary p-1 rounded-full text-white h-fit w-fit" onClick={() => { handleEnviarContactos(empresa.id) }}>
                                        <FiSend className="text-xs" />
                                    </Button>
                                </Tooltip>
                            </div>
                            <div className="bg-primary rounded-full text-white">
                                <Tooltip showArrow={true} content="Información" size='sm' position='top' >
                                    <Button className="bg-primary p-1 rounded-full text-white h-fit w-fit">
                                        <FaInfoCircle className="text-xs" />
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </Card>
              ))}
                <ModalEnviarEmpresas openModal={openModal} close={() => setOpenModal(false)} empresa={empresa} />
           
              </div>
          ) : (
                  empresas.length === 0 && (
                    <div className="grid grid-cols-3 gap-3 min-h-full w-full">
                        {[...Array(3)].map((_, index) => (
                            <Card key={index} className="p-3 flex flex-col justify-between" radius="lg">
                                <Skeleton className="rounded-lg">
                                    <div className="h-40 rounded-lg bg-default-300"></div>
                                </Skeleton>
                                <div className="space-y-3">
                                    <Skeleton className="w-3/5 rounded-lg">
                                        <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
                                    </Skeleton>
                                    <Skeleton className="w-4/5 rounded-lg">
                                        <div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
                                    </Skeleton>
                                    <Skeleton className="w-2/5 rounded-lg">
                                        <div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
                                    </Skeleton>
                                </div>
                            </Card>
                  ))}
                  
                  </div>
              )
          )
      )
}
export default Empresas;