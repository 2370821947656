
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from "@nextui-org/react";
import { useEffect } from "react";
import { Input, Select, SelectItem } from "@nextui-org/react";
import { useState } from "react";
import toast from 'react-hot-toast';
import { useSocket } from "../../contexts/SocketContext";



const AddUser = ({ open, close }) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const socket = useSocket();
  const [user, setUser] = useState({
    username: '',
    name: '',
    lastname: '',
    password: '',
    company: ''
  });

  const handleClose = () => {
    onClose();
    close();
    setUser({ username: '', name: '', lastname: '', password: '', company: '' });
  }

  const EMPRESAS = [
    { value: '1', label: 'Gaman Droom' },
    { value: '2', label: 'Integram Consilis' },
    { value: '3', label: 'JQS Consultores' },
    { value: '4', label: 'Kram Assesores' },
  ];

  const handleGuardar = () => {

    if (user.username === '' || user.name === '' || user.lastname === '' || user.password === '' || user.company === '') {
      toast.error('Todos los campos son obligatorios');
      return;
    }

    const empresa = EMPRESAS[user.company].label;
    user.company = empresa;

    socket.emit('addUser', user, localStorage.getItem('token-autocall-ig'));

    setUser({ username: '', name: '', lastname: '', password: '', company: '' });
    onClose();

  } 


  useEffect(() => {
    if (open) {
      onOpen();
    }
  }
    // eslint-disable-next-line
    , [open]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Añadir Usuario</ModalHeader>
              <ModalBody>

                <Input size="sm" type="text" label="Nombre de usuario" onChange={(e) => setUser({ ...user, username: e.target.value })} value={user.username} />
                <Input size="sm" type="text" label="Nombre" onChange={(e) => setUser({ ...user, name: e.target.value })} value={user.name} />
                <Input size="sm" type="text" label="Apellido" onChange={(e) => setUser({ ...user, lastname: e.target.value })} value={user.lastname} />
                <Input size="sm" type="text" label="Contraseña" onChange={(e) => setUser({ ...user, password: e.target.value })} value={user.password} />
                <Select size="sm" label="Empresa" onChange={(e) => setUser({ ...user, company: e.target.value })} value={user.company}>
                  {EMPRESAS.map((empresa, index) => (
                    <SelectItem key={index} value={empresa.value}>
                      {empresa.label}
                    </SelectItem>

                  ))}
                </Select>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={handleClose}>
                  Cerrar
                </Button>
                <Button color="primary" onPress={handleGuardar} >
                  Guardar
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
export default AddUser