import Sidebar from "../componentes/Sidebar";
import Rutas from "../componentes/Rutas";
import { useEffect } from "react";
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const Main = () => {
    const { isLogged } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLogged) {
            navigate('/login');
        }
        // eslint-disable-next-line
    }, [isLogged, navigate]);


    return (
        <div className="flex flex-row min-h-screen">

            {isLogged && <Sidebar />}
            <div className="flex-1 p-6">
                <Rutas />
            </div>
        </div>
    );
}
export default Main;