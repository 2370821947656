import { Breadcrumbs, BreadcrumbItem } from "@nextui-org/react";

const Configuracion = () => {
    return (
        <section>
        <Breadcrumbs isDisabled className="mb-6">
            <BreadcrumbItem>Principal</BreadcrumbItem>
            <BreadcrumbItem active>Configuración</BreadcrumbItem>
        </Breadcrumbs>
        <h2 className="text-xl">
            Gestiona la configuración 
        </h2>
        <p className="text-gray-400 text-[13px]">Gestiona las opciones relacionadas con el servicio</p>
        <div className="py-3">

        </div>
    
    </section>
    );
    }
    export default Configuracion;