import { Breadcrumbs, BreadcrumbItem } from "@nextui-org/react";
import FormularioDescargarContactos from "../componentes/descargarcontactos/FormularioDescargarContactos";


const DescargarContacto = () => {

    return (
        <section>
            <Breadcrumbs isDisabled className="mb-6">
                <BreadcrumbItem>Principal</BreadcrumbItem>
                <BreadcrumbItem active>Descargar Contactos</BreadcrumbItem>
            </Breadcrumbs>
            <h2 className="text-xl">
                Descargar Contactos
            </h2>
            <p className="text-gray-400 text-[13px]">Descragar todos los contactos de la base de datos en formato .csv o .xlsx</p>
            <div className="py-3">
                <FormularioDescargarContactos />
            </div>

        </section>
    );
}
export default DescargarContacto;